import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import bgTeam from '../images/fundo_time.png'

const useStyles = makeStyles((theme) => ({
  backgroundTeam: {
    height: 'auto',
    backgroundImage: `url('${bgTeam}')`,
    backgroundPosition: 'right -80px top 120px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200px',
    paddingBottom: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'right -15px top 120px',
      backgroundSize: '180px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
    },
  },
  titleTeam: {
    textAlign: 'center',
    fontSize: 48,
    color: '#464646',
    padding: '30px 0 30px 0',
    lineHeight: '42px',
    fontWeight: '700',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      fontSize: 43
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 38
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 33
    },
    "&::before": {
      left: '-30px',
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '370px',
      width: '115px',
      marginTop: '280px',
      transform: 'skew(-30deg)',
      zIndex: '-1',
      [theme.breakpoints.down('md')]: {
        left: '-30px',
        width: '115px',
        height: '250px',
        marginTop: '350px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }
  },
  teamCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  memberTeamCard: {
    height: '300px',
    width: '230px',
    maxWidth: '100%',
    position: 'relative',
    margin: '0 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  teamMember: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  teamMemberInfo: {
    backgroundColor: 'rgba(35, 32, 32, 0.8)',
    padding: '10px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  memberName: {
    color: 'white',
    fontWeight: '400',
    width: '100%',
    textAlign: 'center',
  },
  memberFunction: {
    color: 'white',
    fontWeight: '400',
    fontSize: '0.85rem',
    width: '100%',
    textAlign: 'center',
  },
  teamCardCarousel: {
    paddingBottom: '50px'
  },
  imgTeam:{
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: '294px',
      height: '387px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '294px',
      height: '387px'
    }
  },
  dots: {
    position: 'absolute',
    bottom: '-35px',
    display: 'block',
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    textAlign: 'center',
    '& li': {
      position: 'relative',
      display: 'inline-block',
      width: '8px',
      height: '8px',
      margin: '0 6px',
      padding: 0,
      cursor: 'pointer',
      '& button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: '8px',
        height: '8px',
        padding: '2px',
        cursor: 'pointer',
        color: 'transparent',
        border: 0,
        outline: 'none',
        background: 'transparent',
        '&::before': {
          fontSize: '16px',
          lineHeight: '12px',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '15px',
          height: '15px',
          content: '"•"',
          textAlign: 'center',
          opacity: 0.5,
          color: '#d52e5f',
        },
      },
      '&.slick-active button:before': {
        opacity: 1,
        color: '#d52e5f',
      },
    },
  },
}))

function Team({ team = [] }) {
  const classes = useStyles();
  const [useCarousel, setUseCarousel] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setUseCarousel(width <= 960 || team.length > 5);
      setSlidesToShow(width <= 500 ? 1 : width <= 900 ? 2 : width <= 1200 ? 3 : 4);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [team]);

  const TeamMember = ({ member }) => (
    <div className={classes.memberTeamCard}>
      <img src={member.image} alt={member.description || "member"} className={classes.teamMember} />
      <div className={classes.teamMemberInfo}>
        <Typography className={classes.memberName}>{member.name}</Typography>
        <Typography className={classes.memberFunction}>{member.job_title}</Typography>
      </div>
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      { breakpoint: 900, settings: { slidesToShow: 2 } },
      { breakpoint: 500, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={classes.backgroundTeam} id='team'>
      <Typography data-aos="fade-up" className={classes.titleTeam}>Nosso time</Typography>
      <div data-aos="fade-up" className={useCarousel ? classes.teamCardCarousel : classes.teamCards}>
        {useCarousel ? (
          <Slider {...settings}>
            {team.map(member => <TeamMember key={member.id} member={member} />)}
          </Slider>
        ) : (
          team.slice(0, 5).map(member => <TeamMember key={member.id} member={member} />)
        )}
      </div>
    </div>
  );
}

export default Team;
