import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField, Button } from '@material-ui/core'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import swal from 'sweetalert'
import Aos from 'aos'
import 'aos/dist/aos.css'
import bgContact from '../images/fundo_contact.png'
import emailContact from '../images/email_contact.png'

const useStyles = makeStyles((theme) => ({
  backgroundContact: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#D52E5F',
    justifyContent: 'space-between',
    padding: '60px 80px',
    backgroundImage: `url('${bgContact}')`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 40px',
      backgroundImage: 'none',
    }
  },
  headerContact: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    position: 'relative',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#EA7294',
      width: '100px',
      height: '210px',
      left: 0,
      top: '422px',
      transform: 'skew(-25deg) translateX(-100%)',
      zIndex: '1',
      transition: 'all 0.3s ease-in-out',
      [theme.breakpoints.down('md')]: {
        height: '180px',
        top: '444px'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    }
  },
  titleContact: {
    fontSize: 48,
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 43
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 38
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 33
    }
  },
  bodyContactUs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  address: {
    paddingRight: '30px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '35px',
      paddingRight: '0',
    },
    zIndex: '1',
    position: 'relative'
  },
  addressTitle: {
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold'
  },
  addressSubtitle: {
    fontSize: '18px',
    color: '#FFFFFF',
    marginBottom: '30px'
  },
  emailImage: {
    width: '250px',
    height: 'auto',
    marginTop: '10px',
    marginBottom: '30px'
  },
  formContact: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  formNameEmail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  fieldBase: {
    marginBottom: '30px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white !important',
      },
      '&:hover fieldset': {
        borderColor: 'white !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white !important',
      },
    },
    '& .MuiInputLabel-outlined': {
      color: 'white !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: 'white !important',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white !important',
    },
    '& .MuiFormHelperText-root': {
      color: 'white !important',
      position: 'absolute',
      bottom: '-24px',
      left: '0',
      right: '0',
    },
  },
  fieldNameContact: {
    extend: 'fieldBase',
    width: '343px',
    height: '55px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      marginRight: '0px',
      marginBottom: '30px',
    },
  },
  fieldEmailContact: {
    extend: 'fieldBase',
    width: '343px',
    height: '55px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
  },
  fieldSubjectContact: {
    extend: 'fieldBase',
    width: '700px',
    height: '55px',
    [theme.breakpoints.down('md')]: {
      width: '620px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
  },
  fieldTextContact: {
    extend: 'fieldBase',
    width: '700px',
    minHeight: '200px',
    '& .MuiOutlinedInput-multiline': {
      padding: '0 !important',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      padding: '18.5px 14px !important',
    },
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '620px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
  },
  buttonContact: {
    color: '#D52E5F',
    fontSize: 18,
    width: '140px',
    height: '53px',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderRadius: '0px',
    '&:disabled': {
      background: 'linear-gradient(180deg, #CCCCCC 0%, #CCCCCC 100%)',
      borderImage: 'linear-gradient(180deg, #CCCCCC 0%, #CCCCCC 100%)',
      color: '#D52E5F'
    },
  },
  recaptcha: {
    marginBottom: '24px',
    '& > div > div': {
      margin: '0 auto',
    },
  }
}))

const initialState = { name: '', email: '', subject: '', message: '' }

const validations = {
  name: (value) => value.length > 1,
  email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  subject: (value) => value.length > 1,
  message: (value) => value.length > 1,
}

function Contact() {
  const classes = useStyles()
  const [contact, setContact] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [readyForCaptcha, setReadyForRecaptcha] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(false)

  useEffect(() => {
    const isValid = Object.keys(validations).every(key => validations[key](contact[key]))
    if (isValid && !showRecaptcha) {
      setShowRecaptcha(true)
    }
    setButtonDisabled(!isValid || !contact.recaptchaValue)
  }, [contact, showRecaptcha])

  const handleChange = (e) => {
    const { name, value } = e.target
    setContact(prev => ({ ...prev, [name]: value }))
    validateField(name, value)
  }

  const validateField = (name, value) => {
    setErrors(prev => ({
      ...prev,
      [name]: validations[name](value) ? '' : 'Campo obrigatório!'
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setButtonDisabled(true)
    
    const payload = {
      contact: {
        name: contact.name,
        email: contact.email,
        subject: contact.subject,
        message: contact.message
      },
    }

    axios.post('/home/contact.json', payload)
      .then(() => {
        alertSendMail()
        setReadyForRecaptcha(false)
        setContact(initialState)
        setErrors({})
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors || {})
        } else {
          alertSendMailError()
        }
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  const alertSendMail = () => {
    swal("E-mail enviado!", "Entraremos em contato em breve", "success")
  }

  const alertSendMailError = () => {
    swal("E-mail não enviado!", "Verifique se todos os campos foram preenchidos", "error")
  }

  const recaptchaChange = (value) => {
    setContact(prev => ({ ...prev, recaptchaValue: value }))
  }

  return (
    <div className={classes.backgroundContact} id='contact'>
      <div className={classes.headerContact}>
        <Typography className={classes.titleContact}>Fale conosco</Typography>
      </div>
      <div className={classes.bodyContactUs}>
        <div className={classes.address}>
          <Typography className={classes.addressTitle}>Nosso endereço</Typography>
          <Typography className={classes.addressSubtitle}>Rua do Bom Jesus, 125, 3º andar, Recife - PE</Typography>
          <Typography className={classes.addressTitle}>E-mail</Typography>
          <img src={emailContact} alt="E-mail de contato" className={classes.emailImage} />
        </div>
        <form className={classes.formContact} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={classes.formNameEmail}>
            <TextField 
              className={`${classes.fieldNameContact} ${classes.fieldBase}`}
              name='name' 
              value={contact.name} 
              onChange={handleChange} 
              label="Nome" 
              variant="outlined" 
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField 
              className={`${classes.fieldEmailContact} ${classes.fieldBase}`}
              name='email' 
              value={contact.email} 
              onChange={handleChange} 
              label="E-mail" 
              variant="outlined" 
              error={!!errors.email}
              helperText={errors.email}
            />
          </div>
          <TextField 
            className={`${classes.fieldSubjectContact} ${classes.fieldBase}`}
            name='subject' 
            value={contact.subject} 
            onChange={handleChange} 
            label="Assunto" 
            variant="outlined" 
            error={!!errors.subject}
            helperText={errors.subject}
          />
          <TextField 
            className={`${classes.fieldTextContact} ${classes.fieldBase}`}
            name='message' 
            value={contact.message} 
            onChange={handleChange} 
            multiline 
            minRows={9}
            label="Mensagem" 
            variant="outlined"
            error={!!errors.message}
            helperText={errors.message}
          />

          {showRecaptcha && (
            <ReCAPTCHA 
              className={classes.recaptcha}
              sitekey="6LcKlzUjAAAAALfcLksU1NttmrNr9267GqKbaM_i"
              onChange={recaptchaChange}
            />
          )}
          <Button 
            disabled={buttonDisabled} 
            className={classes.buttonContact} 
            variant="contained" 
            type="submit"
          >
            ENVIAR
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Contact
