import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
  backgroundClients: {
    padding: '30px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  clientTitle: {
    fontSize: 48,
    color: '#464646',
    fontWeight: 'bold',
    marginBottom: '25px',
    [theme.breakpoints.down('md')]: { fontSize: 43 },
    [theme.breakpoints.down('sm')]: { fontSize: 38 },
    [theme.breakpoints.down('xs')]: { fontSize: 33 },
  },
  logoClientsLg: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  logoClientsSm: {
    display: 'none',
    [theme.breakpoints.down('sm')]: { 
      display: 'block',
      width: '100%',
      maxWidth: '100%',
      padding: '0 20px',
    },
  },
  clientLogo: {
    width: '234px',
    height: '62px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '190px',
      height: '52px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '156px',
      height: '41px'
    },
    cursor: 'pointer',
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  sliderWrapper: {
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  slickDots: {
    bottom: '-30px',
    '& li': {
      margin: 0,
    },
    '& li button': {
      padding: 0,
    },
    '& li button:before': {
      fontSize: '12px',
      color: '#D52E5F',
      opacity: 0.25,
    },
    '& li.slick-active button:before': {
      opacity: 1,
    },
  },
  singleDot: {
    '& li': {
      display: 'none',
      '&.slick-active': {
        display: 'inline-block',
      },
    },
  },
  clientsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, 220px)',
      gap: '30px',
      padding: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, 200px)',
      gap: '25px',
      padding: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fit, 180px)',
      gap: '20px',
      padding: '20px',
    },
  },
}))

export default function Clients({ clients = [] }) {
  const classes = useStyles()
  const [slidesToShow, setSlidesToShow] = useState(5)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setSlidesToShow(2)
      } else if (window.innerWidth < 960) {
        setSlidesToShow(3)
      } else if (window.innerWidth < 1280) {
        setSlidesToShow(4)
      } else {
        setSlidesToShow(5)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const ClientLogo = ({ client }) => {
    if (client.site_link) {
      return (
        <a 
          href={`//${client.site_link}`}
          className={classes.clientLogo}
          target="_blank"
          rel="noopener noreferrer"
          title='Clique para visitar o site do cliente'
        >
          <img 
            src={client.logo} 
            alt={client.client_name || 'Client'} 
            className={classes.logoImage}
          />
        </a>
      );
    } else {
      return (
        <div className={classes.clientLogo}>
          <img 
            src={client.logo} 
            alt={client.client_name || 'Client'} 
            className={classes.logoImage}
          />
        </div>
      );
    }
  };

  const settings = {
    dots: true,
    infinite: clients.length > slidesToShow,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: clients.length <= slidesToShow,
    centerPadding: '0px',
    initialSlide: 0,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: clients.length <= 4,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: clients.length <= 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: clients.length <= 2,
        }
      }
    ],
    dotsClass: `slick-dots ${classes.slickDots}`,
    appendDots: dots => (
      <div style={{ bottom: '-30px' }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div style={{
        width: '10px',
        height: '10px',
        border: '1px solid #D52E5F',
        borderRadius: '50%',
        backgroundColor: '#D52E5F',
        opacity: i === currentSlide ? 1 : 0.5,
        boxShadow: i === currentSlide ? 'none' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      }}/>
    ),
  };

  return (
    <div className={classes.backgroundClients} id='clients'>
      <Typography className={classes.clientTitle} data-aos="slide-up">
        Clientes
      </Typography>
      
      <div className={classes.logoClientsLg} data-aos="fade-up">
        {clients.map((client) => (
          <ClientLogo key={client.id} client={client} />
        ))}
      </div>

      <div className={classes.logoClientsSm} data-aos="fade-up">
        <div className={classes.sliderWrapper}>
          <Slider {...settings}>
            {clients.map((client) => (
              <ClientLogo key={client.id} client={client} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
