import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, MenuItem, MenuList, Drawer, IconButton, Slide } from '@material-ui/core';
import { useScrollTrigger } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import MarcaWeb from '../images/marca_web.png';
import SlashImage from './slash1.png';
import { Link } from 'react-scroll';
import debounce from 'lodash.debounce';

const HideOnScroll = React.memo((props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
});

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  appBarScrolled: {
    boxShadow: theme.shadows[4],
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 4),
    },
  },
  logo: {
    width: '135px',
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: '191px',
    },
  },
  menuContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
      width: '100%', 
      marginLeft: theme.spacing(2),
    },
  },
  menuItem: {
    color: '#333',
    fontSize: '1rem',
    fontWeight: 500,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#D52E5F',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      padding: theme.spacing(1, 2),
    },
  },
  contactButton: {
    backgroundColor: '#D52E5F',
    color: '#FFFFFF',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#C01E4F',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      padding: theme.spacing(1, 2),
    },
  },
  mobileMenuButton: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
  },
  mobileMenuItem: {
    textAlign: 'center',
    fontSize: '1.5rem',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  activeMenuItem: {
    backgroundImage: `url(${SlashImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    color: '#D52E5F',
  },
  activeMobileMenuItem: {
    backgroundImage: `url(${SlashImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
    backgroundPosition: 'center',
    color: '#D52E5F',
  },
  link: {
    textDecoration: 'none',
  },
}));

const menuItems = {
  home: 'Início',
  whatwedo: 'O que fazemos',
  cases: 'Cases',
  clients: 'Clientes',
  team: 'Nosso Time',
  blog: 'Blog',
};

const Header = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    const handleScroll = debounce(() => {
      setScrolled(window.pageYOffset > 50);
    }, 100);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }, []);

  const handleSetActive = useCallback((to) => {
    setActiveItem(to);
  }, []);

  const handleClick = useCallback((key) => {
    const isRoot = window.location.pathname === '/';
    const targetElement = document.getElementById(key);

    if (!isRoot) {
      window.location.href = `${window.location.origin}/#${key}`;
    } else if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (open) handleClose();
  }, [open, handleClose]);

  const handleContactClick = useCallback(() => {
    const isRoot = window.location.pathname === '/';
    const targetElement = document.getElementById('contact');

    if (!isRoot) {
      window.location.href = `${window.location.origin}/#contact`;
    } else if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (open) handleClose();
  }, [open, handleClose]);

  const renderMenuItems = useCallback((isMobile = false) => {
    return Object.entries(menuItems).map(([key, value]) => (
      <div key={key} onClick={() => handleClick(key)} className={classes.link}>
        {isMobile ? (
          <MenuItem className={`${classes.mobileMenuItem} ${activeItem === key ? classes.activeMobileMenuItem : ''}`}>
            {value}
          </MenuItem>
        ) : (
          <Typography className={`${classes.menuItem} ${activeItem === key ? classes.activeMenuItem : ''}`}>
            {value}
          </Typography>
        )}
      </div>
    ));
  }, [activeItem, handleClick, classes]);

  return (
    <HideOnScroll {...props}>
      <AppBar className={`${classes.appBar} ${scrolled ? classes.appBarScrolled : ''}`}>
        <Toolbar className={classes.toolbar}>
          <a href="/" rel="noopener noreferrer">
            <img src={MarcaWeb} alt='roadmapps' className={classes.logo} />
          </a>
          <div className={classes.menuContainer}>
            {renderMenuItems()}
            <Button
              component="button"
              onClick={handleContactClick}
              className={classes.contactButton}
            >
              Fale conosco
            </Button>
          </div>
          <IconButton className={classes.mobileMenuButton} onClick={handleToggle}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.mobileMenu }}
          >
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <MenuList>
              {renderMenuItems(true)}
              <MenuItem className={classes.mobileMenuItem} onClick={handleContactClick}>
                Fale conosco
              </MenuItem>
            </MenuList>
          </Drawer>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default React.memo(Header);