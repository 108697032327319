import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import nextLtrIcon from '../images/ooui_next-ltr.png';

const useStyles = makeStyles((theme) => ({
  backgroundCases: {
    backgroundColor: '#D52E5F',
    padding: '15px 0 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  titleCase: {
    textAlign: 'center',
    fontSize: 40,
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: { fontSize: 36 },
    [theme.breakpoints.down('sm')]: { fontSize: 32 },
    [theme.breakpoints.down('xs')]: { fontSize: 28 },
  },
  subtitleCase: {
    textAlign: 'center',
    fontSize: 16,
    color: '#FFFFFF',
    lineHeight: 1.3,
    maxWidth: '700px',
    margin: '0 auto 30px',
    padding: '0 15px',
    [theme.breakpoints.down('sm')]: { fontSize: 15 },
    [theme.breakpoints.down('xs')]: { fontSize: 13 },
  },
  buttonsObjective: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  buttonsComp: {
    color: '#FFFFFF',
    border: '2px solid #FFFFFF',
    padding: '10px 15px',
    fontSize: 14,
    fontWeight: 'normal',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    width: '150px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#D52E5F',
    },
  },
  activeLink: {
    color: '#D52E5F',
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
  },
  selectObjective: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '30px',
      width: '300px',
      background: 'transparent',
      color: '#FFFFFF',
      border: '2px solid #FFFFFF',
      padding: '10px',
      cursor: 'pointer',
      position: 'relative',
    },
  },
  selectComp: {
    background: 'transparent',
    color: '#FFFFFF',
    border: 'none',
    fontSize: 16,
    textAlign: 'center',
    appearance: 'none',
    cursor: 'pointer',
    width: '100%',
    paddingRight: '24px',
    '&:focus': {
      outline: 'none',
    },
  },
  nextIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
  cardCase: {
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    margin: '0 auto',
    width: '350px',
    height: '300px',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  infoCard: {
    backgroundColor: 'rgba(35, 32, 32, 0.8)',
    padding: '16px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '70px',
  },
  titleCard: {
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  subtitleCard: {
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: 1.2,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  carouselContainer: {
    width: '100%',
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '0 40px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  sliderItem: {
    outline: 'none',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  slickDots: {
    bottom: '-30px',
    '& li': {
      margin: 0,
    },
    '& li button': {
      padding: 0,
    },
    '& li button:before': {
      fontSize: '10px',
      color: 'white',
      opacity: 0.25,
    },
    '& li.slick-active button:before': {
      opacity: 1,
    },
  },
  noItemsMessage: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '20px',
  },
}));

const Cases = ({ portfolio_projects = [] }) => {
  const classes = useStyles()
  const [items, setItems] = useState([]);
  const [activeLink, setActiveLink] = useState('allCases');
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();

  const filterOptions = useMemo(() => [
    { value: 'allCases', label: 'TODOS OS CASES', filter: () => true },
    { value: 'apps', label: 'APLICATIVOS', filter: project => project.platform === 'apps' },
    { value: 'web', label: 'WEB', filter: project => project.platform === 'web' },
    { value: 'integrations', label: 'INTEGRAÇÕES', filter: project => project.platform === 'integrations' },
    { value: 'uxui', label: 'UX/UI', filter: project => project.platform === 'uxui' },
  ], []);

  const loadItems = (option) => {
    const selectedFilter = filterOptions.find(filterOption => filterOption.value === option);
    setItems(portfolio_projects.filter(selectedFilter.filter));
    setActiveLink(option);
  };

  useEffect(() => {
    if (portfolio_projects.length > 0) {
      loadItems('allCases');
    }
  }, [portfolio_projects]);

  const handleClick = (item) => {
    if (item.view_detailed) {
      history.push({
        pathname: `/case-detail/${item.id}`,
        state: { data: item }
      });
    }
  };

  const CaseCard = ({ item }) => (
    <div className={classes.cardCase} onClick={() => handleClick(item)}>
      <img 
        src={item.image} 
        alt={item.name || "case"} 
        className={classes.cardImage}
      />
      <div className={classes.infoCard}>
        <Typography className={classes.titleCard}>{item.name}</Typography>
        <Typography className={classes.subtitleCard}>{item.description}</Typography>
      </div>
    </div>
  );

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    dotsClass: `slick-dots ${classes.slickDots}`,
    appendDots: dots => (
      <div style={{ bottom: '-30px' }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div style={{
        width: '10px',
        height: '10px',
        border: '1px solid white',
        borderRadius: '50%',
        backgroundColor: 'white',
        opacity: i === currentSlide ? 1 : 0.5,
        boxShadow: i === currentSlide ? 'none' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      }}/>
    ),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className={classes.backgroundCases} id='cases'>
      <Typography className={classes.titleCase}>Cases</Typography>
      <Typography className={classes.subtitleCase}>
        Cada um desses cases ilustra nosso compromisso com a excelência, criatividade e a busca constante pela solução ideal.
      </Typography>
      
      <div className={classes.buttonsObjective}>
        {filterOptions.map(option => (
          <Button
            key={option.value}
            className={`${classes.buttonsComp} ${activeLink === option.value ? classes.activeLink : ''}`}
            onClick={() => loadItems(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </div>

      <div className={classes.selectObjective}>
        <select
          className={classes.selectComp}
          value={activeLink}
          onChange={(event) => loadItems(event.target.value)}
        >
          {filterOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
        <img 
          src={nextLtrIcon} 
          alt="Next" 
          className={classes.nextIcon}
        />
      </div>

      <div className={classes.carouselContainer}>
        {items.length > 0 ? (
          <Slider {...settings}>
            {items.map((item) => (
              <div key={item.id} className={classes.sliderItem}>
                <CaseCard item={item} />
              </div>
            ))}
          </Slider>
        ) : (
          <Typography className={classes.noItemsMessage}>
            Nenhum case encontrado para esta categoria.
          </Typography>
        )}
      </div>
    </div>
  )
}

export default Cases
