import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Slash from '../images/slash_category_blog.png'

const useStyles = makeStyles((theme) => ({
  backgroundBlog: {
    height: 'auto',
    backgroundPosition: 'right -80px top 120px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200px',
    padding: '0 50px 10px',
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'right -15px top 120px',
      backgroundSize: '180px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
    },
  },
  titleBlog: {
    textAlign: 'center',
    fontSize: 48,
    color: '#464646',
    padding: '30px 0 30px 0',
    lineHeight: '42px',
    fontWeight: '700',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      fontSize: 43
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 38
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 33
    }
  },
  blogCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  postBlogCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '250px',
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  blogPost: {
    width: '500px',
    height: '300px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    }
  },
  blogPostInfo: {
    flex: 1,
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      padding: '0px',
      marginTop: '10px',
    },
  },
  readMoreButton: {
    backgroundColor: '#d52e5f',
    color: 'white',
    padding: '10px 20px',
    textDecoration: 'none',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100px',
    marginTop: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '8px 16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px 12px',
    }
  },
  postName: {
    fontWeight: '400',
    lineHeight: '40px',
    fontSize: '28px',
    paddingBottom: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      padding: '0px 0px 10px 0px'
    },
  },
  postSubtitle: {
    fontWeight: '400',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '0px 0px 20px 0px'
    }
  },
  postFunction: {
    color: 'white',
    fontWeight: '400',
    fontSize: '0.85rem',
    width: '100%',
    textAlign: 'center',
  },
  blogCardCarousel: {
    paddingBottom: '50px'
  },
  imgBlog:{
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: '294px',
      height: '387px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '294px',
      height: '387px'
    }
  },
  dots: {
    position: 'absolute',
    bottom: '-35px',
    display: 'block',
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    textAlign: 'center',
    '& li': {
      position: 'relative',
      display: 'inline-block',
      width: '8px',
      height: '8px',
      margin: '0 6px',
      padding: 0,
      cursor: 'pointer',
      '&.slick-active': {
        '& button::before': {
          opacity: 1,
          color: '#d52e5f',
        }
      },
      '& button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: '8px',
        height: '8px',
        padding: '2px',
        cursor: 'pointer',
        color: 'transparent',
        border: 0,
        outline: 'none',
        background: 'transparent',
        '&::before': {
          fontSize: '16px',
          lineHeight: '12px',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '15px',
          height: '15px',
          content: '"•"',
          textAlign: 'center',
          opacity: 0.5,
          color: '#d52e5f',
        }
      }
    }
  },
}))

const BlogPost = ({ post }) => {
  const classes = useStyles()
  return (
    <div className={classes.postBlogCard}>
      <img src={post.cover} alt={post.title || "post"} className={classes.blogPost} />
      <div className={classes.blogPostInfo}>
        <Typography style={{ color: '#d52e5f', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <img src={Slash} alt="slash" style={{ marginRight: '5px', width: '15px', height: 'auto' }} />
          {Array.isArray(post.categories) ? post.categories.join(', ') : post.categories}
          {post.reading_time > 0 && ` | ${post.reading_time} min.`}
        </Typography>
        <Typography className={classes.postName}>{post.title}</Typography>
        <Typography className={classes.postSubtitle}>{post.subtitle}</Typography>
        <a href={`/blog/${post.slug}`} className={classes.readMoreButton}>
          Ler mais
          <KeyboardArrowRightIcon style={{ verticalAlign: 'middle', marginLeft: '5px', fontSize: '20px' }}/>
        </a>
      </div>
    </div>
  )
}

function Blog({blog = []}) {
  const classes = useStyles()
  const [slidesToShow, setSlidesToShow] = useState(1)

  useEffect(() => {
    if (blog.length <= 2) {
      setSlidesToShow(blog.length)
    } else {
      setSlidesToShow(3)
    }
  }, [blog])

  const settings = {
    dots: true,
    infinite: blog.length > 1,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
  }

  return blog.length > 0 ? (
    <div className={classes.backgroundBlog} id='blog'>
      <Typography data-aos="fade-up" className={classes.titleBlog}>
        Visite nosso blog
      </Typography>
      <div data-aos="fade-up" className={classes.blogCardCarousel}>
        <Slider {...settings}>
          {blog.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </Slider>
      </div>
    </div>
  ) : null;
}

export default Blog
