import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Icon1 from '../images/icon1.png'
import Icon2 from '../images/icon2.png'
import Icon3 from '../images/icon3.png'
import Icon4 from '../images/icon4.png'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
  backgroundWhatWeDo: {
    height: 'auto'
  },
  titleWhatWeDo: {
    textAlign: 'center',
    fontSize: 48,
    color: '#464646',
    lineHeight: 1.5,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: { fontSize: 43 },
    [theme.breakpoints.down('sm')]: { fontSize: 38 },
    [theme.breakpoints.down('xs')]: { fontSize: 33, paddingTop: '0px' }
  },
  subTitleWhatWeDo: {
    textAlign: 'center',
    fontSize: 18,
    color: '#5C5B5B',
    lineHeight: 1.5,
    padding: '15px 200px 50px',
    [theme.breakpoints.down('md')]: { padding: '15px 130px 50px' },
    [theme.breakpoints.down('sm')]: { padding: '15px 100px', fontSize: 17 },
    [theme.breakpoints.down('xs')]: { padding: '15px 30px', fontSize: 15 }
  },
  iconsWhatWeDo: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    padding: '0 200px 60px',
    [theme.breakpoints.down('md')]: { padding: '0 130px 60px' },
    [theme.breakpoints.down('sm')]: { padding: '30px 0 55px' },
    [theme.breakpoints.down('xs')]: { padding: 0 }
  },
  iconImg: {
    textAlign: 'center',
    width: '20%',
    [theme.breakpoints.down('md')]: { width: '20%' },
    [theme.breakpoints.down('sm')]: { width: '34%' },
    [theme.breakpoints.down('xs')]: { width: '51%', padding: '15px 0 50px' }
  },
  titleIcon: {
    fontSize: 15,
    padding: '15px',
    fontWeight: 'bold'
  },
  subTitleIcon: {
    fontSize: 14,
    color: '#2F2F30'
  }
}))

const iconData = [
  { src: Icon3, title: 'Aplicativos', subtitle: 'Android ou iOS, ambos com tecnologia nativa.' },
  { src: Icon2, title: 'Web', subtitle: 'Sites e sistemas web responsivos, rápidos, já instalados na nuvem.' },
  { src: Icon1, title: 'Integrações', subtitle: 'Robôs de integração para conectar seus sistemas existentes.' },
  { src: Icon4, title: 'UX/UI', subtitle: 'Projetos de interface/experiência impactantes para o seu negócio.' }
]

function WhatWeDo() {
  const classes = useStyles()
  
  return (
    <div className={classes.backgroundWhatWeDo} id='whatwedo'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3.0 }}>
        <Typography className={classes.titleWhatWeDo}>O que nós fazemos</Typography>
        <Typography className={classes.subTitleWhatWeDo}>
          Nós criamos produtos sob medida e soluções para todos os tipo de plataformas e dispositivos. 
          Android, iOS, utilizando tecnologia nativa, que é mais rápida e fluida, e aplicações Web, 
          que funcionam em qualquer dispositivo. A nossa engenharia de classe empresarial oferece a 
          melhor experiência possível, garantindo que o produto ou solução funcione perfeitamente em 
          qualquer dispositivo e em qualquer lugar ao redor do globo.
        </Typography>
        <div data-aos="fade-up" className={classes.iconsWhatWeDo}>
          {iconData.map((icon, index) => (
            <div key={index} className={classes.iconImg}>
              <img src={icon.src} alt={icon.title} />
              <Typography className={classes.titleIcon}>{icon.title}</Typography>
              <Typography className={classes.subTitleIcon}>{icon.subtitle}</Typography>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  )
}

export default WhatWeDo
