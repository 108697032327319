import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import MarcaWeb2 from '../images/marca_web2.png'
import Facebook from '../images/facebook.png'
import Instagram from '../images/instagram.png'
import Linkedin from '../images/linkedin.png'
import PortoDigital from '../images/porto_digital.png'

const useStyles = makeStyles((theme) => ({
  backgroundFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 40px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#5E5E5E',
      opacity: 0.23,
      height: '1px',
      left: 0,
      right: 0,
      top: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '15px 20px',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px',
    },
  },
  copyrightLogo: {
    width: '120px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
  socialLogo: {
    width: '30px',
    height: '30px',
    marginLeft: '12px',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px',
      marginLeft: '10px',
    },
  },
  partnerLogo: {
    height: '60px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
  }
}));

function Footer() {
  const classes = useStyles()
  const currentYear = new Date().getFullYear()

  const socialLinks = [
    { href: 'https://www.facebook.com/rdmapps', src: Facebook, alt: 'facebook' },
    { href: 'https://www.linkedin.com/company/rdmapps/', src: Linkedin, alt: 'linkedin' },
    { href: 'https://www.instagram.com/rdmapps/', src: Instagram, alt: 'instagram' },
  ]

  return (
    <footer className={classes.backgroundFooter}>
      <Container className={classes.section}>
        <img src={PortoDigital} alt='Porto Digital' className={classes.partnerLogo} />
      </Container>
      <Container className={classes.section}>
        <img src={MarcaWeb2} alt='roadmapps' className={classes.copyrightLogo} />
      </Container>
      <Container className={classes.section}>
        {socialLinks.map(({ href, src, alt }) => (
          <a key={alt} href={href} target="_blank" rel="noopener noreferrer">
            <img src={src} alt={alt} className={classes.socialLogo} />
          </a>
        ))}
      </Container>
    </footer>
  )
}

export default Footer
