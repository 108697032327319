import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';
import "trix";
import "trix/dist/trix.css";
import bgSectionTwo from '../../images/bgSectionTwo.png';
import item1Features from '../../../assets/images/item1_features.png';
import item2Features from '../../../assets/images/item2_features.png';
import item3Features from '../../../assets/images/item3_features.png';
import item4Features from '../../../assets/images/item4_features.png';
import logoPortoDigital from '../../images/porto_digital.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  titleMain: {
    padding: '40px 120px 0px 120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    height: '400px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '96px'
  },
  titleMainTitle: {
    color: '#D52E5F',
    fontSize: '38px',
    textAlign: 'center'
  },
  titleMainSubtitle: {
    color: '#FFFFFF',
    fontSize: '16px',
    textAlign: 'left'
  },
  content: {
    display: 'flex',
    padding: '40px 200px 40px 200px',
    height: 'auto',
    position: 'relative',
    backgroundImage: `url('${bgSectionTwo}')`,
    backgroundPosition: 'right top 260px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '170px',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `none`,
      padding: '40px 80px 0px 80px',
    },
    '&::before': {
      left: '-30px',
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '370px',
      width: '115px',
      marginTop: '-50px',
      transform: 'skew(-30deg)',
      zIndex: '-1',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    overflow: 'auto',
  },
  features: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '35px'
  },
  featuresTitleComp: {
    textAlign: 'center',
    padding: '0px 0px 20px 0px',
  },
  featuresTitle: {
    fontWeight: 'bold',
    fontSize: 37,
    color: '#D52E5F',
  },
  featuresContainer: {
    padding: '20px 0px 40px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    width: '100%',
  },
  featureItem: {
    textAlign: 'center',
    flex: '0 1 auto',
    minWidth: '200px',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  featureTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  featureSubtitle: {
    color: theme.palette.text.secondary,
  },
  featuresTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  featuresText: {
    fontWeight: 200,
  },
  logo: {
    marginBottom: '5px',
    width: '140px',
    height: '50px',
  },
  casesSection: {
    width: 'auto',
    height: '500px',
    backgroundColor: '#D52E5F',
    position: 'relative',
    overflow: 'hidden',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: 'rgba(255, 185, 203, 0.25)',
      width: '75px',
      height: '205px',
      marginTop: '-0px',
      right: '-40px',
      transform: 'skew(-25deg)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  casesSectionTitleComp: {
    display: 'flex',
    padding: '30px 70px 20px 70px',
    flexDirection: 'column',
    alignItems: 'center'
  },
  casesSectionTitle: {
    textAlign: 'center',
    fontSize: 40,
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: { fontSize: 36 },
    [theme.breakpoints.down('sm')]: { fontSize: 32 },
    [theme.breakpoints.down('xs')]: { fontSize: 28 },
  },
  trixContent: {
    fontFamily: 'Poppins',
    '& .trix-content': {
      '& h1': { 
        fontSize: '38px', 
        marginBottom: '15px', 
        color: '#D52E5F' 
      },
      '& p, & div': { 
        fontSize: '16px',
        lineHeight: '1.6',
        marginBottom: '1.5em',
        color: props => props.hasBackground ? '#FFFFFF' : '#000000' 
      },
      '& div[data-align="right"]': { 
        textAlign: 'right',
        '& figure': {
          margin: '0 0 1em 1em',
          float: 'right',
          maxWidth: '50%'
        }
      },
      '& div[data-align="left"]': { 
        textAlign: 'left',
        '& figure': {
          margin: '0 1em 1em 0',
          float: 'left',
          maxWidth: '50%'
        }
      },
      '& div[data-align="center"]': { 
        textAlign: 'center',
        '& figure': {
          margin: '0 auto 1em auto',
          display: 'block',
          maxWidth: '80%'
        }
      },
      '& figure': {
        margin: '1em 0',
        '& img': {
          maxWidth: '100%',
          height: 'auto'
        },
        '& figcaption': {
          display: 'none'
        }
      }
    }
  },
  featureIcon: {
    width: '64px',
    height: '64px',
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  relatedProjectsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    width: '100%',
  },
  relatedProjectItem: {
    flex: '0 1 auto',
    minWidth: '200px',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  relatedProjectImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  relatedProjectTitle: {
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  relatedProjectDescription: {
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: 1.2,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  carouselContainer: {
    width: '100%',
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '0 40px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  sliderItem: {
    outline: 'none',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  slickDots: {
    bottom: '-30px',
    '& li': {
      margin: 0,
    },
    '& li button': {
      padding: 0,
    },
    '& li button:before': {
      fontSize: '10px',
      color: 'white',
      opacity: 0.25,
    },
    '& li.slick-active button:before': {
      opacity: 1,
    },
  },
  relatedProjectCard: {
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    margin: '0 auto',
    width: '350px',
    height: '300px',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  infoCard: {
    backgroundColor: 'rgba(35, 32, 32, 0.8)',
    padding: '12px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '60px',
  },
  callToActionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    textAlign: 'center',
    height: '100px',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#D52E5F',
      width: '75px',
      height: '180px',
      marginTop: '-0px',
      right: '-50px',
      transform: 'skew(-24deg)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  callToActionText: {
    fontSize: '24px',
    color: '#D52E5F',
    marginRight: '20px',
  },
  callToActionButton: {
    backgroundColor: '#D52E5F',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#C01E4F',
    },
  },
}));

const CaseDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();

  const formatContent = (content) => {
    if (!content) return '';
    const alignments = ['right', 'left', 'center'];
    alignments.forEach(alignment => {
      content = content
        .replace(new RegExp(`<align-${alignment}>`, 'g'), `<div data-align="${alignment}">`)
        .replace(new RegExp(`</align-${alignment}>`, 'g'), '</div>');
    });
    return content;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/detail_project/${id}`);
      setProject(response.data || {});
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [id]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    afterChange: setCurrentSlide,
    dotsClass: `slick-dots ${classes.slickDots}`,
    appendDots: dots => (
      <div style={{ bottom: '-30px' }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div style={{
        width: '10px',
        height: '10px',
        border: '1px solid white',
        borderRadius: '50%',
        backgroundColor: 'white',
        opacity: i === currentSlide ? 1 : 0.5,
        boxShadow: i === currentSlide ? 'none' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      }}/>
    ),
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 1 }},
      { breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 1 }},
      { breakpoint: 800, settings: { slidesToShow: 1, slidesToScroll: 1 }}
    ]
  };

  const handleRelatedProjectClick = (relatedProjectId) => {
    history.push(`/case-detail/${relatedProjectId}`);
  };

  const handleContactClick = () => {
    const isRoot = window.location.pathname === '/';
    const targetElement = document.getElementById('contact');

    if (!isRoot) {
      window.location.href = `${window.location.origin}/#contact`;
    } else if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div>
      <Header />
      {project.image_background || project.title_background || project.subtitle_background ? (
        <div className={classes.titleMain} style={{ backgroundImage: `url(${project.image_background})` }}>
          <Typography className={classes.titleMainTitle}>{project.title_background}</Typography>
          <Typography className={classes.titleMainSubtitle}>{project.subtitle_background}</Typography>
        </div>
      ) : (
        <div style={{ paddingBottom: '80px' }}></div>
      )}
      <div className={classes.content}>
      {console.log('Dados do Projeto:', project)}
      {console.log('Conteúdo Raw:', project.content)}
      {console.log('Conteúdo Formatado:', formatContent(project.content))}
 
        <div 
          className={classes.trixContent}
          dangerouslySetInnerHTML={{ 
            __html: formatContent(project.content)
          }} 
        />
      </div>
      {project.features_items?.some(feature => feature.title || feature.subtitle) && (
        <div className={classes.features}>
          <div className={classes.featuresTitleComp}>
            <Typography className={classes.featuresTitle}>Features Utilizadas</Typography>
          </div>
          <div className={classes.featuresContainer}>
            {project.features_items.map((feature, index) => (
              (feature.title || feature.subtitle) && (
                <div key={feature.id} className={classes.featureItem}>
                  <img 
                    src={[item1Features, item2Features, item3Features, item4Features][index] || null}
                    alt={feature.title}
                    className={classes.featureIcon}
                  />
                  <Typography className={classes.featureTitle}>{feature.title}</Typography>
                  <Typography className={classes.featureSubtitle}>{feature.subtitle}</Typography>
                </div>
              )
            ))} 
          </div>
          <div className={classes.featuresTextContainer}>
            <Typography className={classes.featuresText}>
              Somos uma empresa parceira do
            </Typography>
            <img src={logoPortoDigital} alt="Logo Porto Digital" className={classes.logo} />
          </div>
        </div>
      )}
      {project.related_projects?.length > 0 && (
        <div className={classes.casesSection}>
          <div className={classes.casesSectionTitleComp}>
            <Typography className={classes.casesSectionTitle}>Cases Similares</Typography>
          </div>
          <div className={classes.carouselContainer}>
            <Slider {...settings}>
              {project.related_projects.map(relatedProject => (
                <div key={relatedProject.id} className={classes.sliderItem}>
                  <div className={classes.relatedProjectCard} onClick={() => handleRelatedProjectClick(relatedProject.id)}>
                    <img 
                      src={relatedProject.image}
                      alt={relatedProject.name}
                      className={classes.relatedProjectImage}
                      onError={(e) => {
                        console.error('Erro ao carregar imagem:', relatedProject.image);
                        e.target.style.display = 'none';
                      }}
                    />
                    <div className={classes.infoCard}>
                      <Typography className={classes.relatedProjectTitle}>{relatedProject.name}</Typography>
                      <Typography className={classes.relatedProjectDescription}>{relatedProject.description}</Typography>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
      <div className={classes.callToActionSection}>
        <Typography className={classes.callToActionText}>
          Venha conhecer mais nossas soluções e ferramentas!
        </Typography>
        <Button
          variant="contained"
          className={classes.callToActionButton}
          onClick={handleContactClick}
        >
          Fale conosco
        </Button>
      </div>
      <Footer />
    </div>
  );
}

export default CaseDetail;