import React from 'react'
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-scroll'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { motion } from 'framer-motion'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import bgHome from '../images/fundo1.png'
import EmptyCel from '../images/borda_cel.png'
import InsideCel from '../images/tela_cel.png'

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      contained: {
        '&:hover': {
          backgroundColor: '#d00f4f'
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  backgroundOne: {
    padding: '66px 100px 0px 100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: 'auto',
    backgroundImage: `url('${bgHome}')`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      padding: '85px 25px 0px',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundImage: 'none',
    }
  },
  solutions: {
    maxWidth: '50%',
    "&::before": {
      content: `''`,
      position: 'absolute',
      left: '-460px',
      backgroundColor: '#d52e5f',
      height: '563px',
      width: '500px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
  },
  cellphone: {
    padding: '100px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0',
      transform: 'scale(0.8)',
    }
  },
  titleHome: {
    fontSize: 48,
    color: '#464646',
    lineHeight: '60px',
    padding: '110px 10px 15px 10px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 43,
      lineHeight: '45px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      textAlign: 'center',
      lineHeight: '40px',
      padding: '30px 0 15px',
    }
  },
  subTitleHome: {
    fontSize: 18,
    color: '#5C5B5B',
    lineHeight: '27px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '13px',
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      textAlign: 'center',
      lineHeight: '24px',
      marginLeft: 0,
      marginBottom: '20px',
    }
  },
  phoneDiv: {
    position: 'relative',
    width: '230px',
    height: '440px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      paddingRight: '0px'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px'
    }
  },
  cellphoneImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3
  },
  insideCellPhoneImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  carrosselApps: {
    width: '200px',
    position: 'absolute',
    marginTop: '5px'
  },
  carrosselImgs: {
    height: '440px',
    marginTop: '-10px',
    marginLeft: '1px',
    borderRadius: '50px'
  },
  buttonHome: {
    fontSize: 14,
    backgroundColor: '#d52e5f',
    padding: '10px 25px',
    marginTop: '35px',
    marginLeft: '12px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 13px',
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
      fontSize: 14,
      marginLeft: 0,
      marginTop: '20px',
    }
  },
  buttonContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: '30px',
    }
  },
  buttonMd: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonSm: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  sliderWrapper: {
    position: 'absolute',
    top: '6px',
    left: '14px',
    width: 'calc(100% - 28px)',
    height: 'calc(100% - 15px)',
    overflow: 'hidden',
    borderRadius: '20px',
    zIndex: 2
  },
  sliderImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center top'
  },
  sliderSlide: {
    width: '100% !important',
    height: '100% !important',
  },
}))

const ButtonHome = () => {
  const classes = useStyles()

  return (
    <motion.div 
      className={classes.buttonContainer} 
      initial={{ x: '-70vw', opacity: 0 }} 
      animate={{ x: 0, opacity: 1 }} 
      transition={{ type: 'spring', delay: 0.3, stiffness: 120 }}
    >
      <Button className={classes.buttonHome} variant="contained">
        <Link activeClass="active" to="cases" spy smooth offset={-70} duration={1000} href="#cases" style={{ color: '#FFFFFF' }}>
          ACESSE NOSSOS CASES
          <KeyboardArrowRightIcon style={{ verticalAlign: 'text-bottom', fontSize: 20 }}/>
        </Link>
      </Button>
    </motion.div>
  )
}

const Home = ({ portfolio_projects = [] }) => {
  const classes = useStyles()

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    adaptiveHeight: false,
    centerMode: false,
    variableWidth: false,
    cssEase: 'linear',
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.backgroundOne} id='home'>
        <div className={classes.solutions}>
          <motion.div 
            initial={{ y: '30vw', opacity: 0 }} 
            animate={{ y: 0, opacity: 1 }} 
            transition={{ type: 'spring', delay: 0.3, stiffness: 60 }}
          >
            <Typography className={classes.titleHome}>
              Soluções criativas, eficazes e com tecnologia <span style={{ color: '#D52E5F' }}>de ponta</span>
            </Typography>
            <Typography className={classes.subTitleHome}>
              Somos uma empresa criativa e tecnológica especializada em aplicações mistas (mobile e web). Nossa experiência em usabilidade, design & tecnologia ajuda nossos clientes na construção de um relacionamento interpessoal mais forte com o público através da tecnologia.
            </Typography>
          </motion.div>
          <div className={classes.buttonMd}>
            <ButtonHome />
          </div>
        </div>
        <motion.div 
          initial={{ y: '30vw', opacity: 0 }} 
          animate={{ y: 0, opacity: 1 }} 
          transition={{ type: 'spring', delay: 0.5, stiffness: 60 }} 
          className={classes.cellphone}
        >
          <div className={classes.phoneDiv}>
            <img src={EmptyCel} alt='cellphone' className={classes.cellphoneImg} />
            <div className={classes.sliderWrapper}>
              <Slider {...settings}>
                {portfolio_projects.map((project) => (
                  project.image_banner && (
                    <div key={project.name} className={classes.sliderSlide}>
                      <img 
                        src={project.image_banner}
                        alt={project.name}
                        className={classes.sliderImage}
                      />
                    </div>
                  )
                ))}
              </Slider>
            </div>
          </div>
        </motion.div>
        <motion.div 
          initial={{ y: '30vw', opacity: 0 }} 
          animate={{ y: 0, opacity: 1 }} 
          transition={{ type: 'spring', delay: 0.7, stiffness: 60 }} 
          className={classes.buttonSm}
        >
          <ButtonHome />
        </motion.div>
      </div>
    </ThemeProvider>
  )
}

export default Home
