import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/Header';
import Home from '../components/Home';
import WhatWeDo from '../components/WhatWeDo';
import Cases from '../components/Cases';
import Clients from '../components/Clients';
import Team from '../components/Team';
import Blog from '../components/Blog';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Toolbar from '@material-ui/core/Toolbar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppCovidTest from './pages/terms_of_use/AppCovidTest';
import CaseDetail from './pages/CaseDetail';
import Aos from 'aos';
import 'aos/dist/aos.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  // '&.MuiFab-root': {
  //   backgroundColor: 'green !important',
  //   color: '#fff'
  // }
});

makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        style={{
          zIndex: '2',
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Public() {
  const [data, setData] = useState({});

  function fetchData() {
    axios.get('/api/data.json').then(function (res) {
      console.log('Data fetched');
      setData(res.data || {});
    });
  }

  useEffect(function () {
    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1200 });

    const handleLoad = () => {
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          const targetElement = document.getElementById(hash.substring(1));
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1300); // Ajuste o tempo conforme necessário
      }
    };

    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path={['/', '/public']}>
            <Toolbar id="back-to-top-anchor" style={{ position: 'absolute' }} />
            <Home portfolio_projects={data.portfolio_projects} />
            <WhatWeDo />
            <Cases portfolio_projects={data.portfolio_projects} />
            <Clients clients={data.clients} />
            <Team team={data.team} />
            <Blog blog={data.posts} />
            <Contact id="contact" />
            <Footer />
            <ScrollTop>
              <Fab
                size="small"
                aria-label="scroll back to top"
                style={{ color: '#fff', backgroundColor: '#d52e5f' }}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </Route>
          <Route exact path={['/termos-de-uso/app-teste-covid']}>
            <AppCovidTest />
          </Route>
          <Route exact path={["/case-detail/:id"]}>
            <CaseDetail />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Public;
